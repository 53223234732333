import { joinURL } from 'ufo';
import { servicesService } from '~/services/services';
import { resolverService } from '~/services/resolver';
import { Services } from '~/models/services';
import { ServicesGroup } from '~/models/services-group';

export const useServicesState = () => ({
	servicesState: useState<Services.Model[]>('servicesState', () => []),
	servicesLoaded: useState<boolean>('servicesLoaded', () => false),
	servicesPending: useState<boolean>('servicesPending', () => true),
	servicesPageUrl: useState<string>('servicesPageUrl', () => '/services'),
	servicesGroupState: useState<ServicesGroup.Model[]>('servicesGroupState', () => []),
	servicesGroupLoaded: useState<boolean>('ServicesGroupLoaded', () => false),
	servicesGroupPending: useState<boolean>('ServicesGroupPending', () => true),
});

export const useServices = () => {
	const {
		servicesState,
		servicesLoaded,
		servicesPending,
		servicesPageUrl,
		servicesGroupState,
		servicesGroupLoaded,
		servicesGroupPending,
	} = useServicesState();

	/**
	 * Fetch services
	 */
	const fetchServices = async () => {
		if (servicesLoaded.value) return;

		servicesPending.value = true;

		const [page, state] = await Promise.all([
			resolverService.resolveByCollection('services'),
			servicesService.fetch(),
		]);

		if (page) {
			servicesPageUrl.value = page.url;
		}
		servicesState.value = state;

		servicesLoaded.value = true;
		servicesPending.value = false;
	};

	/**
	 * Refresh services
	 */
	const refreshServices = async () => {
		servicesLoaded.value = false;

		await fetchServices();
	};

	/**
	 * Services list
	 */
	const servicesList = computed(() =>
		servicesState.value.reduce(
			(acc, cur) => [...acc, { ...cur, url: joinURL(servicesPageUrl.value, cur.alias) }],
			[] as Array<Services.Model & { url: string }>
		)
	);

	/**
	 * Fetch services group
	 */
	const fetchServicesGroup = async () => {
		if (servicesGroupLoaded.value) return;

		servicesGroupPending.value = true;

		servicesGroupState.value = await servicesService.fetchGroup();

		servicesGroupLoaded.value = true;
		servicesGroupPending.value = false;
	};

	/**
	 * Refresh services group
	 */
	const refreshServicesGroup = async () => {
		servicesGroupLoaded.value = false;

		await fetchServicesGroup();
	};

	return {
		servicesList,
		servicesState,
		servicesPending,
		servicesGroupState,
		servicesGroupLoaded,
		servicesGroupPending,

		fetchServices,
		refreshServices,
		fetchServicesGroup,
		refreshServicesGroup,
	};
};

export const useServiceState = (alias: string) => ({
	serviceState: useState<Services.Model>(`serviceState__${alias}`, () => Services.modelState),
	serviceLoaded: useState<boolean>(`serviceLoaded__${alias}`, () => false),
	servicePending: useState<boolean>(`servicePending__${alias}`, () => true),
});
export const useService = (alias: string) => {
	const { serviceState, serviceLoaded, servicePending } = useServiceState(alias);

	/**
	 * Fetch service
	 */
	const fetchService = async () => {
		if (serviceLoaded.value) return;

		servicePending.value = true;

		serviceState.value = (await servicesService.getItem(alias)) || Services.modelState;

		serviceLoaded.value = true;
		servicePending.value = false;
	};

	/**
	 * Refresh service
	 */
	const refreshService = async () => {
		serviceLoaded.value = false;

		await fetchService();
	};

	return {
		serviceState,
		servicePending,

		fetchService,
		refreshService,
	};
};
