<template lang="pug">
div(class='SliderSwitcher')
	div(class='SliderSwitcher--Header')
		slot(name='header')

	div(v-if='!single', class='SliderSwitcher--Switcher', :class='{ FullContainerWidth: lessThan("desktop") }')
		UiButton(
			v-for='{ name, id } in groups',
			:key='id',
			:variant='activeGroup === id ? "primary" : "secondary"',
			:size='lessThan("mobileWide") ? "lg" : "md"',
			rounded,
			@click='selectActiveGroup(id)'
		) {{ name }}

	div(class='SliderSwitcher--Link')
		slot(name='link')

	UiCarousel(
		class='SliderSwitcher--Carousel',
		:perView='greaterThan("desktop") ? 1.4 : greaterThan("tablet") ? 1.3 : 1.2',
		:gap='greaterThan("desktop") ? "xl" : greaterThan("mobile") ? "md" : "sm"',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(
					v-for='service in servicesListByGroup',
					:key='service.id',
					v-bind='carouselItemProps'
				)
					div(class='SliderSwitcher--Carousel--Item')
						NuxtLink(v-if='greaterThan("mobileWide")', :to='service.url', class='Image')
							UiImage(:image='service.preview', width='400px', height='400px', lazy)
						NuxtLink(:to='service.url', class='Name') {{ service.name }}
						UiProse(
							v-if='service.shortDescription',
							class='Description',
							:html='service.shortDescription',
							awesome
						)
						UiServiceAction(
							class='Action',
							:form='service.form',
							:price='service.price',
							:credit='service.credit',
							:creditPeriod='service.creditPeriod',
							:button='service.button'
						)

	div(v-if='greaterThan("tablet")', class='SliderSwitcher--CarouselNavigation')
		UiButton(
			class='SliderSwitcher--CarouselNavigation--Button',
			:class='{ Disabled: activeSlide === 1 }',
			variant='secondary',
			rounded,
			square,
			paddet,
			size='xl',
			iconLeft='system/arrow-left',
			iconLeftSize='md',
			@click='slidePrev'
		)
		div(class='SliderSwitcher--CarouselNavigation--Count')
			span {{ activeSlide }} / {{ servicesListByGroup.length }}
		UiButton(
			class='SliderSwitcher--CarouselNavigation--Button',
			:class='{ Disabled: isEndSlide }',
			variant='secondary',
			rounded,
			square,
			size='xl',
			iconRight='system/arrow-right',
			iconRightSize='md',
			@click='slideNext'
		)
</template>

<script lang="ts" setup>
import { ServicesGroup } from '~/models/services-group';
import { UiCarousel } from '#components';
import { TransitionGroup } from 'vue';

const { greaterThan, lessThan } = usePlatform();

const slider = ref<InstanceType<typeof UiCarousel> | null>(null);
const activeSlide = computed(() => slider.value?.activeSlide || 1);
const isEndSlide = computed(() => slider.value?.isEndSlide || false);
const slideNext = () => slider.value?.slideNext();
const slidePrev = () => slider.value?.slidePrev();

const { servicesList, fetchServices } = useServices();

/**
 * Define props
 */
const { groups = [], single = false } = defineProps<{
	groups: ServicesGroup.Model[];
	single?: boolean;
}>();

/**
 * Active group
 */
const activeGroup = ref<number>(groups[0].id);

/**
 * Select active group
 */
const selectActiveGroup = (id: number) => {
	activeGroup.value = id;

	slider.value?.slideTo(0);
	setTimeout(() => {
		slider.value?.sliderReset();
	}, 250);
};

/**
 * Services list by group
 */
const servicesListByGroup = computed(() =>
	servicesList.value.filter(service => service.group.includes(activeGroup.value))
);

await fetchServices();
</script>

<style lang="less" scoped>
.SliderSwitcher {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; start);

	&--Header {
		padding: 0 0 0.25rem 0;
		.grid(1 2, 1 span);
		.box(block; auto; auto; none);
		& > * {
			text-transform: uppercase;
		}
	}

	&--Switcher {
		overflow-x: auto;
		.grid(2 3, 1 span);
		.box(flex; 100%; auto; none; center; flex-start; center; nowrap row);
		& > * {
			margin-right: 0.5rem;
			.flex(0; 0; auto);
			&:first-child {
				margin-left: 1rem;
			}
			&:last-child {
				margin-right: 1rem;
			}
		}
	}

	&--Link {
		.grid(4 5, 1 span);
		.box(block; auto; auto; none);
	}

	&--Carousel {
		.grid(3 4, 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			padding: 1.25rem;
			.box(grid; 100%; 100%; @ColorWhite; 1rem; auto 1fr auto; auto; stretch; start);
			.border-radius(@BorderRadiusSmall);
			& > .Name {
				.grid(1 2; 1 span);
				.text(@ColorBase; 1.25em 1.25rem @semibold; left);
			}
			& > .Description {
				.grid(2 3; 1 span);
				.text(@ColorBase; 1.25em 0.625rem @medium; left);
			}
			& > .Action {
				.grid(3 4; 1 span);
			}

			/* >= 500px */
			@media all and (min-width: @mobile) {
				& > .Name {
					font-size: 1.25rem;
				}
				& > .Description {
					font-size: 0.875rem;
				}
			}

			/* >= 800px */
			@media all and (min-width: @tablet) {
				padding: 0 1.875rem 0 0;
				.box(grid; 100%; 100%; @ColorWhite; 0.625rem 1.875rem; 1.25rem auto 1fr auto 1.25rem; auto 1fr; stretch; start);
				.border-radius(@BorderRadiusMedium);

				& > .Image {
					.grid(5 span; 1 2);
					.border-radius(@BorderRadiusMedium);
					.box(block; 15.625rem; 100%; fade(@ColorBase, 15%));
					overflow: hidden;
					& > img {
						object-fit: cover;
						.box(block; 100%; 100%);
					}
				}
				& > .Name {
					.grid(2 3; 2 3);
				}
				& > .Description {
					.grid(3 4; 2 3);
				}
				& > .Action {
					.grid(4 5; 2 3);
				}
			}

			/* >= 1000px */
			@media all and (min-width: @laptop) {
				& > .Image {
					width: 18.75rem;
				}
				& > .Name {
					font-size: 1.75rem;
				}
				& > .Description {
					font-size: 1rem;
					font-weight: @regular;
				}
			}

			/* >= 1200px */
			@media all and (min-width: @desktop) {
				& > .Image {
					width: 25rem;
				}
			}

			/* >= 1300px */
			@media all and (min-width: @large-screen-small) {
				& > .Name {
					font-size: 2.25rem;
					font-weight: @medium;
				}
				& > .Description {
					font-size: 1.125rem;
					font-weight: @regular;
				}
			}
		}
	}

	&--CarouselNavigation {
		.box(grid; auto; auto; none; 2rem; 1fr; 1fr auto 1fr; center; start; column);
		&--Button {
			padding: 1.375rem !important;
			.grid(1 span);

			&.Disabled {
				border-color: transparent;
				background: fade(@ColorBase, 5%);
				.cursor-default();
				.box-shadow(0; 0; 0; 0; transparent) !important;
			}
		}
		&--Count {
			.grid(1 span);
			.text(@ColorBase; 1em 1.5rem @medium);
		}
	}

	/* >= 1000px */
	@media all and (min-width: @laptop) {
		.box(grid; auto; auto; none; 1rem 2rem; auto; auto 1fr auto; center; start);
		&--Header {
			.grid(1 2, 1 span);
		}
		&--Switcher {
			overflow: unset;
			.grid(2 3, 2 span);
			& > * {
				&:first-child {
					margin-left: unset;
				}
				&:last-child {
					margin-right: unset;
				}
			}
		}
		&--Link {
			.grid(2 3, 1 span);
		}
		&--Carousel {
			.grid(3 4, 3 span);
		}
		&--CarouselNavigation {
			.grid(4 5, 3 span);
		}
	}

	/* >= 1300px */
	@media all and (min-width: @large-screen-small) {
		.box(grid; auto; auto; none; 2rem 3rem; auto; auto 1fr auto; center; start);
		&--Switcher {
			.grid(1 2, 1 span);
		}
		&--Link {
			.grid(1 2, 1 span);
		}
		&--Carousel {
			.grid(2 3, 3 span);
		}
		&--CarouselNavigation {
			.grid(3 4, 3 span);
		}
	}
}
</style>
