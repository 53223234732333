import { Common } from '~/models/common';
import { Services } from '~/models/services';
import { ServicesGroup } from '~/models/services-group';

const collection = 'services';
const collectionGroup = 'services_group';

export const servicesService = {
	fetch: async (): Promise<Services.Model[]> => {
		const { getItems } = useDirectusItems();

		const response = await getItems<
			Omit<Services.Model, 'group'> & { group: { services_group_id: { id: number } }[] }
		>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
				},
				fields: [
					'*',
					'preview.*',
					'seo.title',
					'seo.header',
					'seo.keywords',
					'seo.description',
					'group.services_group_id.id',
					'form.*',
				],
			},
		});

		/** Resolve services group */
		const servicesList = response.reduce((acc, service) => {
			const group = service.group.map(g => g.services_group_id.id);

			acc.push({ ...service, group });
			return acc;
		}, [] as Services.Model[]);

		return servicesList;
	},

	fetchGroup: async (): Promise<ServicesGroup.Model[]> => {
		const { getItems } = useDirectusItems();

		return await getItems<ServicesGroup.Model>({
			collection: collectionGroup,
			params: {
				filter: {
					pageView: true,
					status: Common.Status.PUBLISHED,
				},
				fields: ['*'],
			},
		});
	},

	getItem: async (alias: string): Promise<Services.Model | undefined> => {
		const { getSingletonItem } = useDirectusItems();

		const [response] = await getSingletonItem<[Services.Model]>({
			collection,
			params: {
				filter: {
					alias: alias,
					status: Common.Status.PUBLISHED,
				},
				fields: [
					'*',
					'preview.*',
					'seo.title',
					'seo.header',
					'seo.keywords',
					'seo.description',
					'group.services_group_id.id',
					'form.*',
				],
			},
		});

		return response || undefined;
	},
};
