import { Common } from '~/models/common';
import { Image } from '~/models/image';
import { Form } from '~/models/form';
import { Seo } from '~/models/seo';

export namespace Services {
	export type Model = Common.Model & {
		name: string | null;
		alias: string;
		preview: Image.Model;
		price: number;
		credit: boolean;
		creditPeriod: number;
		shortDescription: string | null;
		fullDescription: string | null;
		infoBlock: string | null;
		group: number[];
		button: string | null;
		form: Form.Model | null;
		seo: Seo.Model | null;
	};

	export const modelState: Model = {
		...Common.modelState,
		name: null,
		alias: '',
		preview: Image.modelState,
		price: 0,
		credit: false,
		creditPeriod: 0,
		shortDescription: null,
		fullDescription: null,
		infoBlock: null,
		group: [],
		button: 'Оформить',
		form: Form.modelState,
		seo: Seo.modelState,
	};
}
